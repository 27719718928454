module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-segment-js@5.0.0_gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18._coowys2euu4nvx3i3tqjsosbky/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"qi1VKe4zhGsGpMQPEtkdv4GTGI47hLqG","devKey":"jIDe0ZNs3rFmi0GdacZYkBIS3AspDFfb","trackPage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_p6tp2vhzzlgf2yk226k27utzjy/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Thoughtly Marketing","short_name":"Thoughtly","start_url":"/","background_color":"#F6F6F4","theme_color":"#F6F6F4","display":"browser","icon":"./src/images/global/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"18b0d83440bcb33a203c7a5bb0b07b4c"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.13.1_gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__react-dom@1_dg5cckrqb4mvpt5blyrs37whda/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-41ECDSBNCV","AW-11413179986"],"pluginConfig":{"head":false,"respectDNT":false,"delayOnRouteUpdate":0,"exclude":[],"origin":"https://www.googletagmanager.com"},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_@babel+core@7.23._kuyrhhbvxp2gg7e5jgcnnwalb4/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.6_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__exil6f4susumwjmclbulh5bjzu/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
